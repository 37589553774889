export enum BLOCK_TYPE {
  HEADER = 'header',
  PARAGRAPH = 'paragraph',
  PARAGRAPH_WITH_TITLE = 'paragraphWithTitle',
  HORIZONTAL_PRODUCTS_WITH_TITLE = 'horizontalProducts',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  ADDRESS = 'address',
  PRODUCTS = 'products',
  PRODUCTS_WITH_TITLE = 'productsWithTitle',
  IMAGE_BLOCK = 'imageBlock',
  VIDEO_BLOCK = 'videoBlock',
  QUOTE = 'quote',
  IMAGE_WITH_TEXT = 'imageWithTextBlock',
  ABOUT_US = 'aboutUs',
  GALLERY_PRODUCTS = 'galleryProducts',
  IMPRESSIONS = 'impressions',
  CENTERED_TEXT = 'centeredText',
  PRODUCTS_WITH_BASIC_TITLE = 'productsWithBasicTitle',
  SIMPLE_LEGAL_BLOCK = 'SimpleLegalBlock',
  FAQ_LEGAL_BLOCK = 'FAQLegalBlock',
  ORGANIZED_LEGAL_BLOCK = 'OrganizedLegalBlock',
  ARCHIVED_EDITORIAL_BLOCK = 'archivedEditorialBlock',
  NEWSLETTER_BLOCK = 'newsletterBlock',
  EMBED_HTML = 'embedHTML',
  MAP = 'map',
  COLUMNS = 'columns',
  BIOGRAPHY = 'biography',
  SHOPPABLE_IMAGE = 'shoppableImageBlock',
  TWO_PIECE_EDITORIAL = 'articles',
  ADVERTISEMENT = 'advertisementBlock',
}

export const CONTAINER_WIDTH = 710;
export const SIDEBAR_WIDTH = 252;
export const CONTAINER_PADDING = 36;
export const NARROW_CONTAINER_WIDTH = 540;

export enum ASSET_PLACEMENT {
  NORMAL = 'normal',
  WIDE = 'wide',
}

export enum PARAGRAPH_WITH_TITLE_OPTION {
  QUESTION = 'question',
  ANSWER = 'answer',
  BIOGRAPHY = 'biography',
}

export enum INTRO_TEXT_FONT_SIZE {
  SMALL = 'small',
  LARGE = 'large',
}

export enum INTRO_TEXT_FONT_COLOR {
  WHITE = 'white',
  BLACK = 'black',
}

export enum INTRO_TEXT_POSITION {
  TOP = 'top',
  LEFT = 'left',
}

export enum IMAGE_WITH_TEXT_IMAGE_OPTION {
  CENTERED = 'centered',
  FULL_BLEED = 'full_bleed',
  CENTERED_WITH_TITLE = 'centered_with_title',
}

export enum SIMPLE_LEGAL_BLOCK_TITLE {
  TOP = 'top',
  BOTTOM = 'bottom',
  BLANK = 'blank',
}
